import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FeedItems = makeShortcode("FeedItems");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "qa--dev"
    }}>{`QA > Dev`}</h1>
    <p>{`On this page we document some of the things quality analysts may have wanted to express, but couldn't find the right time or the right words. This should help us build a better product together. `}</p>
    <p>{`Or, in other words, this blog will explain some of the reasons why QA does the crazy things it does.  `}</p>
    <h2 {...{
      "id": "quality-infused--development"
    }}>{`Quality Infused >>> Development`}</h2>
    <FeedItems filterByTags={['qa-to-dev']} mdxType="FeedItems" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      